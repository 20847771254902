<template>
  <div class="wrapper">
    <div class="login">
      <div class="login-triangle"></div>
      
      <h2 class="login-header">Toyota ERC Calculator</h2>

      <form class="login-container" onsubmit="event.preventDefault();">
        <p>Input your 16 digit code that is shown on your screen.</p>

        <p>Please donate to support my work.</p>

        <p><input type="text" name="erc" placeholder="ERC" minlength="16" maxlength="16" required v-model="inputCode"></p>
        <p><input type="text" name="code" placeholder="Unlock Code" disabled v-model="unlockCode"></p>
        <p><input type="submit" class="submit" value="Generate" action="#" @click="getUnlockCode"/></p>
        <p><input type="button" id="link" value="Donate" @click="showDonate = true"></p>
      </form>
    </div>
    
    <div id="popup" v-if="showDonate">
      <Donate />
    </div>

    <div id="popupdarkbg" v-if="showDonate" @click="showDonate = false" />
  </div>
</template>

<script>
import Donate from "./../components/Donate"

export default {
  components: {
    Donate
  },
  data() {
    return {
      showDonate: false,
      inputCode: null,
      unlockCode: null,
    }
  },
  mounted() {
    if (this.$route.query.showDonate && this.$route.query.showDonate == "true") {
      this.showDonate = true
    }
  },
  methods: {
    getUnlockCode() {
      if (this.inputCode && this.inputCode.length == 16) {
        this.unlockCode = "Loading..."

        fetch('https://erc.melvinchng.com/generate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            erc: this.inputCode
          }),
        }).then(response => response.json())
        .then(data => {
          this.unlockCode = data.code
        });
      }
    }
  }
}
</script>

<style scoped>
/* 'Open Sans' font from Google Fonts */
@import url(//fonts.googleapis.com/css?family=Open+Sans:400,700);

.wrapper {
  background: #456;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

.login {
  width: 90%;
  max-width: 400px;
  margin: 0px auto;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 16px;
}

/* Reset top and bottom margins from certain elements */
.login-header,
.login p {
  margin-top: 0;
  margin-bottom: 0;
}

/* The triangle form is achieved by a CSS hack */
.login-triangle {
  width: 0;
  margin-right: auto;
  margin-left: auto;
  border: 12px solid transparent;
  border-bottom-color: #28d;
}

.login-header {
  background: #28d;
  padding: 20px;
  font-size: 1.4em;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.login-container {
  background: #ebebeb;
  padding: 12px;
}

/* Every row inside .login-container is defined with p tags */
.login p {
  padding: 12px;
}

.login input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  padding: 16px;
  outline: 0;
  font-family: inherit;
  font-size: 0.95em;
}

.login input[type="email"],
.login input[type="password"] {
  background: #fff;
  border-color: #bbb;
  color: #555;
}

/* Text fields' focus effect */
.login input[type="email"]:focus,
.login input[type="password"]:focus {
  border-color: #888;
}

.login .submit {
  background: #28d;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
}

.login .submit:hover {
  background: #17c;
}

/* Buttons' focus effect */
.login .submit:focus {
  border-color: #05a;
}

#popup { 
  display: block; /* none */
  position: fixed; 
  top: 12%; 
  left: 15%; 
  width: 70%; 
  height: 70%; 
  background-color: white; 
  z-index: 10; 
}

#popup iframe { 
  width: 100%; 
  height: 100%; 
  border: 0; 
}

#popupdarkbg { 
  position: fixed; 
  z-index: 5; 
  left: 0; 
  top: 0; 
  width: 100%; 
  height: 100%; 
  overflow: hidden; 
  background-color: rgba(0,0,0,.75); 
  display: block; /* none */
}
</style>
<template>
  <body>
    <div class="row">
      <div class="small-12 columns">
        <h2>Donate Today</h2>
      </div>
    </div>
    <div class="row">
      <div class="small-12 columns">
        <p>Thank you for choosing to make a donation. Your support
          will help reduce the server cost needed.</p>
      </div>
      <div class="small-12 columns">
        <p>You will be redirected to Stripe to complete your donation. For each donation, Stripe takes away 2.9% plus $0.30 per transaction for the fees.</p>
      </div>
    </div>
    <div class="row">
      <div class="small-12 columns">
        <h3>Your contribution</h3>
        <ul class="small-block-grid-1 medium-block-grid-2 no-bullets">
          <!-- li onclick="goToStripe(this.id)" id="price_1IV8WOLkqYUA6tsbKjwpFzIU">
            <input type="radio" name="donate-amount">
            <label for="donate-amount-1">$1</label>
          </li -->
          <li @click="redirectToCheckout('price_1IV8WOLkqYUA6tsb1Dygl1oY')">
            <input type="radio" name="donate-amount">
            <label for="donate-amount-2">$5</label>
          </li>
          <li @click="redirectToCheckout('price_1IV8WOLkqYUA6tsbv06eRxsJ')">
            <input type="radio" name="donate-amount">
            <label for="donate-amount-3">$10</label>
          </li>
          <!-- <li onclick="goToStripe(this.id)" id="price_1IV8WOLkqYUA6tsbQL0fPn9s">
            <input type="radio" name="donate-amount">
            <label for="donate-amount-4">$20</label>
          </li>
          <li onclick="goToStripe(this.id)" id="price_1IV8WOLkqYUA6tsbAjCyWLtk">
            <input type="radio" name="donate-amount">
            <label for="donate-amount-5">$50</label>
          </li>
          <li onclick="goToStripe(this.id)" id="price_1IV8WOLkqYUA6tsbHXn4UXLQ">
            <input type="radio" name="donate-amount">
            <label for="donate-amount-6">$100</label>
          </li> -->
        </ul>
      </div>
    </div>
  </body>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
    redirectToCheckout(price_id) {
      const isTest = false

      if (isTest) {
        price_id = "price_1IV5i3LkqYUA6tsbcw1sfKkE"
      }

      const publishableKey = isTest ? "pk_test_Jy1EkOOs0HZPvZeNTJgEyxWJ" : "pk_live_Ci3erFLsJ5rzqr6S4zZYHLTh"

      var stripe = window.Stripe(publishableKey);

      stripe.redirectToCheckout({
        lineItems: [
          {
            "price": price_id,
            "quantity": 1
          }
        ],
        submitType: "donate",
        mode: 'payment',
        successUrl: 'https://melvinchng.com/erc',
        cancelUrl: 'https://melvinchng.com/erc?showDonate=true',
      }).then(function (result) {
        if (result.error) {
          console.log('Error opening Stripe payment page.');
        }
      });
    }
  }
}
</script>

<style scoped>
@import url(//cdnjs.cloudflare.com/ajax/libs/foundation/5.0.0/css/foundation.min.css);

body {
  padding: 1em;
  max-width: 35em;
  margin: 0 auto;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.error {
  color: red;
}

label .optional {
  font-weight: normal;
  font-size: 90%;
}

input[type=radio] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

input {
  font-size: 1.4em;
  line-height: 1;
  border: 2px solid #3b7840;
  padding: 0.55em 0.55em 0.55em 0.8em;
  color: #3b7840;
}

label {
  display: block;
  font-size: 1.4em;
  font-weight: normal;
  background: #3b7840;
  color: #fff;
  padding: 0.5em;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

label:hover,
label:focus {
  background: #48854c
}

label.active {
  background: #e9bd1d;
  color: #171303;
  text-shadow: none
}

</style>